* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
  letter-spacing: .08rem;
  width: 100%;
  height: 100%;
  font-family: Work Sans, serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4;
  position: relative;
}

ul {
  list-style: none;
}

a:link, a:visited, a:hover, a:active {
  color: #fff;
  text-decoration: none;
}

th {
  text-align: left;
  padding: 0 1rem;
  line-height: 1.1;
}

td {
  padding: .5rem 1rem;
}

.hidden {
  display: none !important;
}

.hidden-quote {
  opacity: 0;
  filter: blur(5px);
  transition: all .5s;
  transform: translateX(-100px);
}

.hidden-animation {
  opacity: 0;
  filter: blur(5px);
  transition: all 2s;
  transform: translateX(-100px);
}

@keyframes revealText {
  0% {
    clip-path: inset(0 50%);
  }

  100% {
    clip-path: inset(0);
  }
}

.card-anime {
  opacity: 1 !important;
  filter: blur() !important;
  transform: translateX(0) !important;
}

.card-anime:nth-child(2) {
  transition-delay: .2s;
}

.card-anime:nth-child(3) {
  transition-delay: .4s;
}

.show {
  opacity: 1;
  filter: blur();
  transform: translateX(0);
}

.page--container {
  opacity: 1;
}

.invisible {
  opacity: 0 !important;
}

.ph {
  text-shadow: none;
}

.ph-fill {
  color: #a1000b;
  font-size: 2.4rem;
}

.icon {
  cursor: pointer;
  font-size: 2.4rem;
}

.noscroll {
  overflow: hidden !important;
}

.underline {
  text-decoration: underline;
}

.section-nav {
  background-color: #fdf9f5;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 9rem;
  transition: all .3s;
  display: grid;
}

.container-logo:link, .container-logo:visited, .container-logo:hover, .container-logo:active {
  color: #777;
  align-items: center;
  height: 9rem;
  font-size: larger;
  display: flex;
}

.list-nav {
  justify-content: end;
  gap: 10%;
  padding-right: 5%;
  font-family: Georgia, Times New Roman, Times, serif;
  font-size: large;
  font-weight: 600;
  display: flex;
}

.list-nav .list-item a:link, .list-nav .list-item a:visited, .list-nav .list-item a:hover, .list-nav .list-item a:active {
  color: #a1000b;
  white-space: nowrap;
}

.container-logo .logo-img {
  height: 75%;
}

.menu {
  cursor: pointer;
  background-color: #fff3;
  height: fit-content;
  padding: .5rem .8rem;
  display: none;
  box-shadow: 0 0 .2rem #999;
}

.section--footer {
  color: #fff;
  background-color: #000;
  grid-template-rows: 1fr min-content;
  display: grid;
}

.list-el--footer {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  width: 90%;
  height: 6rem;
  margin: 0 auto;
  list-style: none;
  display: grid;
}

.list-el--footer.read {
  grid-template-columns: 1fr 1fr;
}

.item-el--footer {
  white-space: nowrap;
  align-items: center;
  gap: 1.2rem;
  display: flex;
}

.icon--contact {
  cursor: pointer;
}

.frankel--link-container {
  border-top: .01px solid #fafafa;
  padding: 1rem 0;
}

.frankel--link {
  align-items: end;
  gap: 1.2rem;
  width: fit-content;
  margin: 0 auto;
  font-family: Playwrite HU, cursive;
  font-size: 2.1rem;
  display: flex;
}

.guitar--img {
  height: 8rem;
}

.copyright--footer {
  white-space: nowrap;
  text-align: center;
  align-content: center;
  justify-items: center;
  width: 85%;
  height: 5rem;
  margin: 0 auto;
  font-size: 1.5rem;
}

.section--introduction {
  text-align: center;
  background-color: #fdf9f5bf;
  flex-direction: column;
  gap: 2.1rem;
  width: 100%;
  margin: 2.4rem auto 0;
  padding: 4.8rem 0;
  display: flex;
}

.section--introduction h3 {
  letter-spacing: .1rem;
  font-size: 2.4rem;
}

.section--introduction bold {
  color: #a1000b;
  font-style: italic;
}

.section--introduction p {
  width: 50%;
  margin: 0 auto;
}

.section--form {
  opacity: 0;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: .3s forwards fade-in;
  display: flex;
  position: fixed;
  top: 0%;
  left: 0%;
}

.section-form--lang {
  background-color: #000000b3;
}

.icon--close-form {
  color: #333;
  margin: 1.2rem;
  position: absolute;
  top: .5%;
  right: .5%;
}

.form {
  background-color: #fdf9f5;
  border-radius: 1rem;
  flex-direction: column;
  gap: 3.6rem;
  width: 80%;
  padding: 4.8rem 0;
  animation: .3s forwards expand;
  display: flex;
  position: relative;
  transform: scale(0);
  box-shadow: 0 .4rem .4rem .6rem #0000000a;
}

.form--lang {
  grid-template-columns: 1fr 1fr;
  padding: 3.6rem;
  display: grid;
}

.form--lang p:hover {
  cursor: pointer;
  text-decoration: underline;
}

.form div {
  flex-direction: column;
  gap: 1.2rem;
  width: 80%;
  margin: 0 auto;
  display: flex;
}

.form--input, .form--textarea {
  padding: 1rem;
  font-size: 1.7rem;
}

.form--textarea {
  height: 10rem;
}

.submit-btn {
  color: #fff;
  cursor: pointer;
  text-shadow: none;
  background-color: #91000a;
  border: .1rem solid #91000a;
  border-radius: 3.6rem;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 5rem;
  margin: 0 auto;
  font-size: 1.7rem;
  transition: all .2s;
  display: flex;
}

.submit-btn:hover {
  background-color: #aa1a23;
}

.card--btn {
  cursor: pointer;
  border: .1rem solid #000;
  border-radius: 3.6rem;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 4rem;
  margin: 0 auto;
  transition: all .2s;
  display: flex;
}

.card--btn-side:link, .card--btn-side:visited {
  color: #000;
  text-decoration: none;
}

.card--btn-side:hover, .card--btn-side:active {
  color: #fff;
  background-color: #a1000b;
}

.form--quote {
  text-align: center;
  margin: 0 auto;
  font-style: italic;
  width: 50% !important;
}

.collapse {
  animation: .3s forwards collapse;
}

.fade-in {
  animation: 1s forwards fade-in;
}

.fade-out {
  animation: 1s forwards fade-out;
}

@keyframes expand {
  0% {
    transform: scale(.1);
  }

  20% {
    transform: scale(.2);
  }

  30% {
    transform: scale(.3);
  }

  40% {
    transform: scale(.4);
  }

  50% {
    transform: scale(.5);
  }

  60% {
    transform: scale(.6);
  }

  70% {
    transform: scale(.7);
  }

  80% {
    transform: scale(.8);
  }

  90% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes collapse {
  0% {
    transform: scale(.9);
  }

  20% {
    transform: scale(.8);
  }

  30% {
    transform: scale(.7);
  }

  40% {
    transform: scale(.6);
  }

  50% {
    transform: scale(.5);
  }

  60% {
    transform: scale(.4);
  }

  70% {
    transform: scale(.3);
  }

  80% {
    transform: scale(.2);
  }

  90% {
    transform: scale(.1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
/*# sourceMappingURL=home.1c4144f9.css.map */
